import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { Footer } from "../components/Footer";
import Navigation from "../components/Navigation";

export default function AboutPage() {
    return (
        <div className="font-['Source_Serif_4'] bg-black text-white">
            <Navigation />

            <div className="w-full grid md:grid-cols-2 max-w-7xl mx-auto">
                <StaticImage
                    src="../images/big.webp"
                    alt=""
                    style={{
                        objectFit: "cover",
                        width: "100%",
                        height: "100%",
                        minHeight: "100vh",
                    }}
                />

                <div className="p-8 mx-auto max-w-prose text-justify grid justify-items-center">
                    <h2 className="mb-4 text-3xl md:pt-36">OUR FREEDOM</h2>

                    <h2 className="mb-4 text-3xl">OUR FUTURE</h2>

                    <p className="mb-4">
                        Tulsi Gabbard launched Our Freedom, Our Future PAC to protect our freedoms and support leaders
                        who share her commitment to uphold and protect our God-given rights enshrined in the
                        Constitution. Our founders envisioned a government that is of, by, and for the people — not of,
                        by, and for the powerful elite. We need to elect leaders who are committed to the same.
                    </p>
                    <p className="mb-4">
                        Please donate today! With your help, we will continue spreading our message of peace, security,
                        prosperity and freedom, and support leaders who put the best interests of the American people
                        and our country first.
                    </p>
                    <div className="text-lg">Stand with Tulsi &trade;</div>
                    <div className="text-xl m-8 lg:m-6 lg:text-2xl">
                        <a
                            className="rounded-md p-3 bg-red-500"
                            href="https://secure.anedot.com/ourfreedomourfuture/donate"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Donate Now
                        </a>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}
